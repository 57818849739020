// src/hooks/useMappy.js

import { useState, useEffect, useRef, useMemo } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

function useMappy({ autoExtract = false }) {
  // --- State Variables ---
  const [data, setData] = useState([]);
  const [headerRow, setHeaderRow] = useState(0);
  const [startRow, setStartRow] = useState(1);
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isSortVisible, setIsSortVisible] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const fileInputRef = useRef(null);

  const [uploadedFileName, setUploadedFileName] = useState('');

  // Addresses after validation
  const [validatedAddresses, setValidatedAddresses] = useState([]);

  // Zones after handleSubmit
  const [zones, setZones] = useState([]);

  const [headerRowError, setHeaderRowError] = useState('');
  const [startRowError, setStartRowError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [currentLocation, setCurrentLocation] = useState(null);

  const [totalExpectedTime, setTotalExpectedTime] = useState(0); // in minutes
  const [zoneExpectedTimes, setZoneExpectedTimes] = useState([]); // array of times per zone

  // --- Manage Sort Visibility ---
  useEffect(() => {
    setIsSortVisible(columnsOptions.length > 0 && selectedColumns.length > 0);
  }, [columnsOptions, selectedColumns]);

  // --- Build columnsOptions ---
  useEffect(() => {
    if (data.length > 0 && headerRow !== null && headerRow !== undefined) {
      const headerRowIndex = headerRow - 1;
      const maxColumns = data.reduce((max, row) => Math.max(max, row.length), 0);
      let excelHeaders = [];

      if (headerRowIndex >= 0 && headerRowIndex < data.length) {
        const headerData = data[headerRowIndex];
        excelHeaders = Array.from({ length: maxColumns }, (_, index) => {
          const headerValue = headerData[index];
          return {
            label:
              headerValue !== undefined && headerValue !== ''
                ? headerValue
                : `Column ${String.fromCharCode(65 + index)}`,
            value: String.fromCharCode(65 + index),
          };
        });
      } else {
        // No header row specified; use column letters
        excelHeaders = Array.from({ length: maxColumns }, (_, index) => ({
          label: `Column ${String.fromCharCode(65 + index)}`,
          value: String.fromCharCode(65 + index),
        }));
      }
      setColumnsOptions(excelHeaders);
    } else {
      // Reset columnsOptions and selectedColumns when headerRow is null
      setColumnsOptions([]);
      setSelectedColumns([]);
    }
  }, [data, headerRow]);

  // --- Clear validated addresses if user deselects columns ---
  useEffect(() => {
    if (selectedColumns.length === 0) {
      setValidatedAddresses([]);
      setZones([]);
      setTotalExpectedTime(0);
      setZoneExpectedTimes([]);
    }
  }, [selectedColumns]);

  // --- Derived Addresses from Data + Selected Columns ---
  const addresses = useMemo(() => {
    if (!data || data.length === 0 || selectedColumns.length === 0) {
      return [];
    }
    const startIndex = startRow - 1;
    return data
      .slice(startIndex)
      .map((row) => {
        const parts = selectedColumns
          .map((col) => {
            const idx = XLSX.utils.decode_col(col.trim().toUpperCase());
            return row[idx] !== undefined && row[idx] !== ''
              ? row[idx].toString().trim()
              : '';
          })
          .filter(Boolean)
          .join(', ');

        return parts
          ? {
              id: uuidv4(),
              row,
              address: parts,
              isValid: null,
              latitude: null,
              longitude: null,
              distanceFromCurrentLocation: null, // Initial distance
              durationFromCurrentLocation: null, // Initial duration
              distanceToNext: null, // Routing distance
              durationToNext: null, // Routing duration
            }
          : null;
      })
      .filter(Boolean);
  }, [data, selectedColumns, startRow]);

  // --- Geolocation ---
  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation not supported by this browser.'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (pos) =>
            resolve({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            }),
          (err) => reject(err)
        );
      }
    });
  };

  /**
   * calculateDistance function that sends a request to the PHP backend server.
   * @param {number} lat1 - Latitude of the first location.
   * @param {number} lon1 - Longitude of the first location.
   * @param {number} lat2 - Latitude of the second location.
   * @param {number} lon2 - Longitude of the second location.
   * @returns {Promise<{distance: number, duration: number}>} - Distance in kilometers and duration in minutes.
   */
  const calculateDistance = async (lat1, lon1, lat2, lon2) => {
    console.log(`Calculating distance from (${lat1}, ${lon1}) to (${lat2}, ${lon2})`);
    try {
      const response = await axios.post('/calculate_distance.php', {
        lat1,
        lon1,
        lat2,
        lon2,
      });

      if (
        response.data &&
        typeof response.data.distance === 'number' &&
        typeof response.data.duration === 'number'
      ) {
        console.log('Distance calculation successful:', response.data);
        return {
          distance: response.data.distance,
          duration: response.data.duration,
        };
      } else {
        throw new Error('Invalid response from backend.');
      }
    } catch (error) {
      console.error('Error calculating distance:', error.message);
      throw new Error('Failed to calculate distance.');
    }
  };

  // --- Helpers ---
  const shortenUrl = async (longUrl) => {
    try {
      const response = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longUrl)}`
      );
      return response.data;
    } catch (error) {
      console.error('Error shortening URL:', error);
      return null;
    }
  };

  // --- Reset Function ---
  const resetData = () => {
    setData([]);
    setColumnsOptions([]);
    setSelectedColumns([]);
    setIsSortVisible(false);
    setOrderBy('');
    setSortOrder('asc');
    setHeaderRow(0);
    setStartRow(1);
    setValidatedAddresses([]);
    setZones([]);
    setUploadedFileName('');
    setHeaderRowError('');
    setStartRowError('');
    setCurrentLocation(null);
    setTotalExpectedTime(0);
    setZoneExpectedTimes([]);
  };

  const handleReset = () => {
    resetData();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (file) => {
    resetData();
    setIsLoading(true);
    setUploadedFileName(file.name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryStr = evt.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(sheetData);
      setIsLoading(false);
      message.success('File uploaded successfully.');
    };
    reader.readAsBinaryString(file);
  };

  const handleOrderByChange = (value) => {
    setOrderBy(value);
    // If orderBy is changed, trigger recalculation of distanceToNext
    if (value) {
      recalculateDistanceToNext();
    }
  };

  const handleSortOrderChange = (value) => {
    setSortOrder(value);
    // If sortOrder is changed, trigger recalculation of distanceToNext
    if (orderBy) {
      recalculateDistanceToNext();
    }
  };

  const handleHeaderRowChange = (value) => {
    setHeaderRow(value);

    if (value !== null && value !== undefined) {
      setStartRow(value + 1);
      message.info(`Start Data Row automatically set to ${value + 1} because Header Row is ${value}.`);

      // Validate headerRow
      if (value < 0 || value > data.length) {
        setHeaderRowError(`Header Row must be between 0 and ${data.length}.`);
      } else {
        setHeaderRowError('');
      }
    }

    setValidatedAddresses([]);
    setZones([]);
    setTotalExpectedTime(0);
    setZoneExpectedTimes([]);
  };

  const handleStartRowChange = (value) => {
    if (value >= 1 && value >= headerRow + 1) {
      setStartRow(value);
      setValidatedAddresses([]);
      setZones([]);
      setTotalExpectedTime(0);
      setZoneExpectedTimes([]);

      // Validate startRow
      if (value < 1) {
        setStartRowError('Start row must be >= 1.');
      } else if (headerRow !== null && value <= headerRow) {
        setStartRowError(`Start Data Row must be greater than Header Row (${headerRow}).`);
      } else if (value > data.length) {
        setStartRowError(`Start Data Row cannot exceed total rows (${data.length}).`);
      } else {
        setStartRowError('');
      }
    }
  };

  // --- Validate Addresses ---
  const validateAddresses = async () => {
    if (addresses.length === 0) {
      message.warning('No addresses to validate.');
      return;
    }

    setIsValidating(true);

    try {
      const loc = await getCurrentLocation();
      setCurrentLocation(loc);
    } catch (err) {
      message.error('Could not retrieve location.');
      setCurrentLocation(null);
    }

    const updatedAddresses = [...addresses];
    for (let i = 0; i < updatedAddresses.length; i++) {
      const addrObj = updatedAddresses[i];
      if (!addrObj.isValid && addrObj.latitude === null && addrObj.longitude === null) {
        try {
          const resp = await axios.post('/validate_address.php', {
            address: addrObj.address,
          });
          const { is_valid, latitude, longitude } = resp.data;
          addrObj.isValid = is_valid;
          addrObj.latitude = is_valid ? latitude : null;
          addrObj.longitude = is_valid ? longitude : null;

          if (is_valid && currentLocation) {
            // Calculate distance from current location to this address
            const { distance, duration } = await calculateDistance(
              currentLocation.latitude,
              currentLocation.longitude,
              latitude,
              longitude
            );
            addrObj.distanceFromCurrentLocation = distance;
            addrObj.durationFromCurrentLocation = duration;
          }

          // If there's a previous valid address, calculate distance to previous address
          if (is_valid && i > 0) {
            const prevAddr = updatedAddresses[i - 1];
            if (prevAddr.isValid && prevAddr.latitude !== null && prevAddr.longitude !== null) {
              const { distance: distanceToPrev, duration: durationToPrev } = await calculateDistance(
                prevAddr.latitude,
                prevAddr.longitude,
                latitude,
                longitude
              );
              prevAddr.distanceToNext = distanceToPrev;
              prevAddr.durationToNext = durationToPrev;
            }
          }
        } catch (err) {
          console.error(`Error validating "${addrObj.address}":`, err);
          addrObj.isValid = false;
          addrObj.latitude = null;
          addrObj.longitude = null;
        }
      }
    }

    // Remove any null or empty address entries
    const finalValidatedAddresses = updatedAddresses.filter(
      (addr) => addr !== null && addr.address !== ''
    );

    setValidatedAddresses(finalValidatedAddresses);
    calculateTotalExpectedTime(finalValidatedAddresses);
    setIsValidating(false);
    message.success('Address validation and distance calculations completed.');
  };

  /**
   * Function to calculate total expected time based on initial distances.
   * @param {Array} addressesArr - Array of validated address objects.
   */
  const calculateTotalExpectedTime = (addressesArr) => {
    let totalTime = 0;
    addressesArr.forEach((addr) => {
      if (addr.durationFromCurrentLocation) {
        totalTime += addr.durationFromCurrentLocation;
      }
      if (addr.durationToNext) {
        totalTime += addr.durationToNext;
      }
    });
    setTotalExpectedTime(totalTime);
  };

  /**
   * Recalculate distanceToNext for all addresses based on current order
   */
  const recalculateDistanceToNext = async () => {
    if (validatedAddresses.length < 2) return;

    const updatedAddresses = [...validatedAddresses];
    for (let i = 0; i < updatedAddresses.length - 1; i++) {
      const current = updatedAddresses[i];
      const next = updatedAddresses[i + 1];

      if (
        current.isValid &&
        next.isValid &&
        current.latitude &&
        current.longitude &&
        next.latitude &&
        next.longitude
      ) {
        try {
          const { distance, duration } = await calculateDistance(
            current.latitude,
            current.longitude,
            next.latitude,
            next.longitude
          );
          current.distanceToNext = distance;
          current.durationToNext = duration;
        } catch (err) {
          console.error(`Error calculating distance between "${current.address}" and "${next.address}":`, err);
          current.distanceToNext = null;
          current.durationToNext = null;
        }
      } else {
        current.distanceToNext = null;
        current.durationToNext = null;
      }
    }

    // Reset the last address's distanceToNext
    if (updatedAddresses.length > 0) {
      updatedAddresses[updatedAddresses.length - 1].distanceToNext = null;
      updatedAddresses[updatedAddresses.length - 1].durationToNext = null;
    }

    setValidatedAddresses(updatedAddresses);
    calculateTotalExpectedTime(updatedAddresses);
  };

  // --- Submit Handler ---
  const handleSubmit = async () => {
    if (validatedAddresses.length === 0) {
      message.warning('No validated addresses to process.');
      return;
    }

    // Check for invalid addresses
    const invalidAddresses = validatedAddresses.filter(
      (addr) => !addr.isValid || !addr.latitude || !addr.longitude
    );
    if (invalidAddresses.length > 0) {
      message.warning('Some addresses are invalid. Please ensure all addresses are valid before submitting.');
      return;
    }

    setIsSubmitting(true);

    // If currentLocation is not set, attempt to get it
    if (!currentLocation) {
      try {
        const loc = await getCurrentLocation();
        setCurrentLocation(loc);
      } catch (err) {
        message.error('Could not retrieve current location.');
        setIsSubmitting(false);
        return;
      }
    }

    // Create zones based on ordered validated addresses
    const zonesSplit = splitAddressesIntoZones(validatedAddresses);

    // Calculate routing distances and expected times
    await calculateRoutingDistancesAndTimes(zonesSplit);

    // Generate Google Maps routes
    if (zonesSplit.length === 1) {
      const zone = zonesSplit[0];
      const destination = zone[zone.length - 1].address;
      const waypoints = zone.slice(0, -1).map((addr) => addr.address).join('|');
      const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        destination
      )}&waypoints=${encodeURIComponent(waypoints)}`;
      window.open(url, '_blank');
    } else {
      // Handle multiple zones (e.g., generate shortened URLs)
      const newZones = [];
      for (const [index, zone] of zonesSplit.entries()) {
        const destination = zone[zone.length - 1].address;
        const waypoints = zone.slice(0, -1).map((addr) => addr.address).join('|');
        const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          destination
        )}&waypoints=${encodeURIComponent(waypoints)}`;
        const shortLink = await shortenUrl(url);
        newZones.push({
          id: uuidv4(),
          zoneNumber: index + 1,
          addresses: zone,
          googleMapsUrl: shortLink || url,
        });
      }
      setZones(newZones);
    }

    setIsSubmitting(false);
    message.success('Zones created and times calculated successfully.');
  };

  /**
   * Split addresses into zones, each containing up to 10 addresses.
   * @param {Array} addresses - Array of validated address objects.
   * @returns {Array} - Array of zones.
   */
  const splitAddressesIntoZones = (addresses) => {
    const zones = [];
    const zoneSize = 10;
    for (let i = 0; i < addresses.length; i += zoneSize) {
      const zone = addresses.slice(i, i + zoneSize);
      zones.push(zone);
    }
    return zones;
  };

  /**
   * Function to calculate routing distances and expected times.
   * @param {Array} zonesArr - Array of zones with addresses.
   */
  const calculateRoutingDistancesAndTimes = async (zonesArr) => {
    console.log('Starting routing distance and time calculations.');
    let totalTime = 0; // in minutes
    const timesPerZone = [];

    for (let zoneIndex = 0; zoneIndex < zonesArr.length; zoneIndex++) {
      const zone = zonesArr[zoneIndex];
      let zoneTime = 0;

      // If no addresses in this zone, skip
      if (zone.length === 0) {
        timesPerZone.push(zoneTime);
        continue;
      }

      // 1) For the FIRST zone => distance from current location to the first address
      if (zoneIndex === 0 && currentLocation) {
        try {
          const { distance, duration } = await calculateDistance(
            currentLocation.latitude,
            currentLocation.longitude,
            zone[0].latitude,
            zone[0].longitude
          );
          // Update the first address with routing distance and duration
          const firstAddrIndex = validatedAddresses.findIndex(addr => addr.id === zone[0].id);
          if (firstAddrIndex !== -1) {
            validatedAddresses[firstAddrIndex].distanceToNext = distance;
            validatedAddresses[firstAddrIndex].durationToNext = duration;
          }
          zoneTime += duration; // already in minutes
          totalTime += duration;
          console.log(`Zone ${zoneIndex + 1}: Routing distance to first address calculated.`);
        } catch (err) {
          console.error('Error calculating routing distance to first address:', err);
        }
      }
      // 2) For SUBSEQUENT zones => distance from the last address of the PREVIOUS zone to the first address of the CURRENT zone
      else if (zoneIndex > 0) {
        const prevZone = zonesArr[zoneIndex - 1];
        if (prevZone.length > 0) {
          const lastAddrPrevZone = prevZone[prevZone.length - 1];
          const firstAddrCurrentZone = zone[0];
          try {
            const { distance, duration } = await calculateDistance(
              lastAddrPrevZone.latitude,
              lastAddrPrevZone.longitude,
              firstAddrCurrentZone.latitude,
              firstAddrCurrentZone.longitude
            );
            // Update the first address of the current zone with routing distance and duration
            const firstAddrIndex = validatedAddresses.findIndex(addr => addr.id === firstAddrCurrentZone.id);
            if (firstAddrIndex !== -1) {
              validatedAddresses[firstAddrIndex].distanceToNext = distance;
              validatedAddresses[firstAddrIndex].durationToNext = duration;
            }
            zoneTime += duration; // already in minutes
            totalTime += duration;
            console.log(`Zone ${zoneIndex + 1}: Routing distance from previous zone calculated.`);
          } catch (err) {
            console.error('Error calculating routing distance between zones:', err);
          }
        }
      }

      // 3) Distances/time AMONG addresses in the CURRENT zone
      for (let i = 0; i < zone.length - 1; i++) {
        const addrCurrent = zone[i];
        const addrNext = zone[i + 1];
        try {
          const { distance, duration } = await calculateDistance(
            addrCurrent.latitude,
            addrCurrent.longitude,
            addrNext.latitude,
            addrNext.longitude
          );
          // Update the current address with routing distance and duration to the next address
          const currentAddrIndex = validatedAddresses.findIndex(addr => addr.id === addrCurrent.id);
          if (currentAddrIndex !== -1) {
            validatedAddresses[currentAddrIndex].distanceToNext = distance;
            validatedAddresses[currentAddrIndex].durationToNext = duration;
          }
          zoneTime += duration; // already in minutes
          totalTime += duration;
          console.log(`Zone ${zoneIndex + 1}: Routing distance within zone between address ${i + 1} and ${i + 2} calculated.`);
        } catch (err) {
          console.error('Error calculating routing distance within zone:', err);
        }
      }

      timesPerZone.push(zoneTime);
      console.log(`Zone ${zoneIndex + 1}: Expected time added.`);
    }

    // Update the state with routing distances
    setValidatedAddresses([...validatedAddresses]);
    setTotalExpectedTime(totalTime);
    setZoneExpectedTimes(timesPerZone);
    console.log('Routing distance and time calculations completed.');
  };

  // --- Drag and Drop Handlers ---
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(validatedAddresses);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setValidatedAddresses(reorderedItems);
    // After reordering, distances to next need to be recalculated
    recalculateDistanceToNext();
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    const updatedAddresses = [...validatedAddresses];
    [updatedAddresses[index - 1], updatedAddresses[index]] = [
      updatedAddresses[index],
      updatedAddresses[index - 1],
    ];
    setValidatedAddresses(updatedAddresses);
    // After moving, distances to next need to be recalculated
    recalculateDistanceToNext();
  };

  const handleMoveDown = (index) => {
    if (index === validatedAddresses.length - 1) return;
    const updatedAddresses = [...validatedAddresses];
    [updatedAddresses[index + 1], updatedAddresses[index]] = [
      validatedAddresses[index],
      validatedAddresses[index + 1],
    ];
    setValidatedAddresses(updatedAddresses);
    // After moving, distances to next need to be recalculated
    recalculateDistanceToNext();
  };

  const handleRemoveAddress = (index) => {
    const updatedAddresses = validatedAddresses.filter((_, idx) => idx !== index);
    setValidatedAddresses(updatedAddresses);
    // After removal, distances to next need to be recalculated
    recalculateDistanceToNext();
  };

  // --- Extract and Validate Addresses ---
  const extractAndValidateAddresses = async () => {
    await validateAddresses();
  };

  // --- Return State and Handlers ---
  return {
    data,
    headerRow,
    setHeaderRow,
    headerRowError,
    startRow,
    setStartRow,
    startRowError,
    columnsOptions,
    selectedColumns,
    setSelectedColumns,
    isSortVisible,
    orderBy,
    sortOrder,
    fileInputRef,
    addresses,
    validatedAddresses,
    setValidatedAddresses,
    zones,
    zoneExpectedTimes,
    totalExpectedTime,
    uploadedFileName,
    isLoading,
    isValidating,
    isSubmitting,
    handleReset,
    handleFileChange,
    handleOrderByChange,
    handleSortOrderChange,
    handleHeaderRowChange,
    handleStartRowChange,
    extractAndValidateAddresses, // For MappyV1
    validateAddresses,
    handleSubmit,
    handleOnDragEnd,
    handleMoveUp,
    handleMoveDown,
    handleRemoveAddress,
    shortenUrl,
  };
}

export default useMappy;
