import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Layout, Menu, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LanguageSwitcher from './components/LanguageSwitcher';
import Home from './pages/Home';
import Version1 from './pages/Version1';
import Version2 from './pages/Version2';
import Notify from './pages/Notify';
import About from './pages/About';
import './App.css';

const { Header, Content } = Layout;

function App() {
  const { t, i18n } = useTranslation();
  const [isInBosnia, setIsInBosnia] = useState(false);
  const [language, setLanguage] = useState(null); // Initialize as null
  const [loading, setLoading] = useState(true); // Loading state to handle API fetching

  useEffect(() => {
    const initializeApp = async () => {
      const savedLanguage = localStorage.getItem('language');

      if (savedLanguage) {
        setLanguage(savedLanguage);
      }

      try {
        const response = await axios.get('https://dev.mappy.ba/geo.php');
        if (response.data && response.data.countryCode) {
          const countryCode = response.data.countryCode;

          // If no language is set in localStorage, determine it based on country
          if (!savedLanguage) {
            if (countryCode === 'BA') {
              setLanguage('bs'); // Set to Bosnian if in Bosnia and Herzegovina
            } else if (['DE', 'AT', 'CH', 'LI', 'LU', 'BE'].includes(countryCode)) {
              setLanguage('de'); // German for other countries
            } else {
              setLanguage('en'); // Default to English
            }
          }

          // Set isInBosnia for Notify component visibility
          setIsInBosnia(countryCode === 'BA');
        } else {
          // If API response doesn't have countryCode, default to English
          if (!savedLanguage) {
            setLanguage('en');
          }
          setIsInBosnia(false);
        }
      } catch (error) {
        console.error('Error fetching user location:', error);
        if (!savedLanguage) {
          setLanguage('en'); // Default to English in case of error
        }
        setIsInBosnia(false);
      } finally {
        setLoading(false); // Stop loading once the API call is done
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    if (language) {
      // Change language in i18n when the language changes
      i18n.changeLanguage(language);

      // Save the selected language to localStorage for future visits
      localStorage.setItem('language', language);
    }
  }, [language, i18n]);

  const menuItems = [
    { label: <Link to="/">{t('menu.home', 'Home')}</Link>, key: 'home' },
    { label: <Link to="/version1">{t('menu.version1', 'Version 1')}</Link>, key: 'version1' },
    { label: <Link to="/version2">{t('menu.version2', 'Version 2')}</Link>, key: 'version2' },
    { label: <Link to="/notify">{t('menu.notifyMe', 'Notify Me')}</Link>, key: 'notifyMe' },
    { label: <Link to="/about">{t('menu.about', 'About')}</Link>, key: 'about' },
  ];

  return (
    <Router>
      <Layout>
        {/* Conditionally render Header only when not loading */}
        {!loading && language && (
          <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Menu theme="dark" mode="horizontal" items={menuItems} style={{ flex: 1 }} />
            <LanguageSwitcher language={language} setLanguage={setLanguage} /> {/* Language switcher */}
          </Header>
        )}
        <Content style={{ padding: '20px' }}>
          {/* Show loader while loading or language is not set */}
          {loading || !language ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/version1" element={<Version1 />} />
              <Route path="/version2" element={<Version2 />} />
              <Route path="/notify" element={<Notify isInBosnia={isInBosnia} />} />
            </Routes>
          )}
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
