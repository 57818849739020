import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

// Language Switcher Component
function LanguageSwitcher({ language, setLanguage }) {
  const changeLanguage = (lng) => {
    setLanguage(lng); // Update the language when user selects a new one
  };

  return (
    <div style={{ marginLeft: 'auto' }}>
      <Select
        value={language} // Use the current language (from localStorage or default)
        style={{ width: 120 }}
        onChange={changeLanguage}
        aria-label="Select Language"
      >
        <Option value="en">English</Option>
        <Option value="de">Deutsch</Option>
        <Option value="bs">Bosanski</Option>
      </Select>
    </div>
  );
}

export default LanguageSwitcher;
